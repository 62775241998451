<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>    
  </div>
</template>

<script>
import { HTTP } from '@/axois.config'
import MainLayout from './layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'
import AdminLayout from './layouts/AdminLayout'
export default {
  provide: {
    http: HTTP
  },
  components: { MainLayout, EmptyLayout, AdminLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Play:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Ubuntu:wght@300;400;700&display=swap');
#app {
  width: 100%;
  height: 100vh;
  color: $font-color-1;
  font-family: 'Play', 'Arial', sans-serif;
}
</style>
