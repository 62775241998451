import { mapState } from 'vuex'

export const access_mix = {
  computed: {
    ...mapState({
      user: state => state.user
    }),
    isAdmin() {
      return this.user.role === '1' ? true : false
    },
    isHr() {
      return this.user.role === '2' ? true : false
    }
  }
}
