import HttpLoader from '../common/HttpLoader'

export const loader_mix = {
  components: { HttpLoader },
  data() {
    return {
      loading: false
    }
  }
}
