<template>
  <div class="main-layout">
    <div class="main-layout__header">
      <a href="/">
        <img
          src="../assets/img/logo-2.png"
          class="main-layout__logo"
          alt="Логотип"
        />
      </a>

      <nav class="main-layout__nav main-nav">
        <a class="main-nav__phone" href="tel:+79133119990"></a>
        <button class="main-nav__toggle" @click="showMenu = !showMenu"></button>
        <ul          
          class="main-nav__list"
          :class="{
            'main-nav__list--showed': showMenu,
          }"
        >
          <li class="main-nav__item" @click="showMenu = false">
            <router-link to="/">Главная</router-link>
          </li>

          <li class="main-nav__item main-nav__item--submenu">
            <a>Услуги</a> 
            <ul class="main-nav__submenu" @click="showMenu = false">
              <li class="main-nav__subitem">
                <router-link to="/geodesy">Геодезия</router-link>
              </li>

              <li class="main-nav__subitem">
                <router-link to="/engineering">Проектирование</router-link>
              </li>

              <li class="main-nav__subitem">
                <router-link to="/building">Строительство</router-link>
              </li>
            </ul>            
          </li>

          <li class="main-nav__item main-nav__item--submenu">
            <a>О компании</a> 
            <ul class="main-nav__submenu" @click="showMenu = false">
              <li class="main-nav__subitem">
                <router-link to="/workers">Сотрудники</router-link>
              </li>

              <li class="main-nav__subitem">
                <router-link to="/documents">Документы</router-link>
              </li>

              <li class="main-nav__subitem">
                <router-link to="/vacancy">Вакансии</router-link>
              </li>
            </ul> 
          </li>

          <li class="main-nav__item" @click="showMenu = false">
            <router-link to="/contacts">Контакты</router-link>
          </li>

          <li class="main-nav__item" @click="showMenu = false">
            <router-link to="/articles">Новости</router-link>
          </li>
        </ul>

        <p class="main-nav__contacts">
          <span>364024, г. Грозный, ул. Им. Анисимова, дом 29а, офис 72</span>
          <a href="tel:+79133119990">+7-913-311-9990</a>
        </p>
      </nav>
    </div>

    <div class="main-layout__body">
      <router-view />
    </div>

    <div class="main-layout__footer footer">
      <div class="wrapper">
        <div class="footer__container">
          <div class="footer__logo">
            <img src="../assets/img/logo-2.png" alt="">
          </div>
          <ul class="footer__menu">
            <li class="footer__item">
              <router-link to="/">Главная</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/geodesy">Геодезия</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/engineering">Проектирование</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/building">Строительство</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/contacts">Контакты</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/vacancy">Вакансии</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/articles">Новости</router-link>
            </li>
          </ul>
        </div>

        <p class="footer__copyrights">
          Кемерово, 2022
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>