import FormSuccess from '../FormSuccess.vue'
import { loader_mix } from '@/components/mixins/loader_mix'

export const form_submit = {
  components: { FormSuccess },
  mixins: [ loader_mix ],
  inject: ['http'],
  data() {
    return {
      message: '',
      isSuccess: true,
      isDone: false,
    }
  },
  computed: {
    isValid() {
      let valid = true

      this.requiredKeys.forEach(key => {
        if (!this.formData[key]) {
          valid = false
        }
      })
      return valid
    }
  },
  methods: {
    onSubmit(path) {
      this.loading = true
      this.http.post(path, this.formData).then(res => {
        this.isDone = true
        this.isSuccess = true
        this.message = res.data.message
      })
      .catch(e => {
        this.isDone = true
        this.isSuccess = false
        this.message = e
      })
      .finally(() => this.loading = false)
    }
  },
  mounted() {
    this.message = ''
    this.isSuccess = true
    this.isDone = false
  }
}
