import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { HTTP } from '@/axois.config'
import { updateItem, deleteItem } from '@/methods/listActions'
export default new Vuex.Store({
  state: {
    branches: [],
    user: {role: '1'}
  },
  getters: {

  },
  mutations: {
    saveBranches(state, branches) {
      state.branches = branches
    },
    replaceBranch(state, branch) {
      updateItem(branch, state.branches)
    },
    updateBranchWorker(state, params) {
      console.log(params)
      let branch = state.branches.find(item => item._id === params.worker.branch)
      console.log(branch)
      if (branch) {
        params.update ? updateItem(params.worker, branch.workers) : deleteItem(params.worker._id, branch.workers)
      }  
    },
    saveUser(state, user) {
      state.user = user
    }
  },
  actions: {
    getBranches(ctx) {
      HTTP.get("branches/").then((res) => {
        let branches = res.data
        ctx.commit('saveBranches', branches)
      });
    },
    updateBranch(ctx, requestData) {
      let { method, url, data } = requestData

      HTTP({ method, url, data }).then((res) => {
        let branch = res.data.branch

        ctx.commit('replaceBranch', branch)
      });
    }
  },
  modules: {

  }
})
