function updateItem(el, arr) {
  let same = arr.find(item => item._id === el._id)

  if (same) {
    let index = arr.indexOf(same)

    arr.splice(index, 1, el)
  } else {
    arr.unshift(el)
  }
}

function deleteItem(id, arr) {
  console.log(id, arr)
  let same = arr.find(item => item._id === id)
  let index = arr.indexOf(same)
  arr.splice(index, 1)
}

export { updateItem, deleteItem }
