<template>
  <div class="home">
    <section class="home__we we">
      <div class="wrapper">
        <div class="we__container">
          <div class="we__item">
            <h1 class="home__title"> ООО «ПромТехСтрой» </h1>
            <p class="home__text">
              Образовано в июне 2019 года, группой специалистов, которые уже успели зарекомендовать себя с лучшей стороны на рынке геодезических, проектных и строительных услуг.
            </p>

            <button 
              class="we__button button button--confirm"
              @click="openPopup('FormComponent', null)"
            >
              Связаться
            </button>
          </div>
          <div class="we__item">
            <video 
              class="we__video" 
              src="/storage/PromTehStroj-kopiya.mp4"
              loop controls
            ></video>
          </div>
        </div>
      </div>      
    </section>

    <section class="home__services services">
      <div class="wrapper">
        <h2 class="section-title">Услуги</h2>
        <div class="services__blocks">
          <div class="services__block">
            <div class="services__image">
               <img src="../assets/img/services-1.png" alt="">
            </div>
           
            <h3 class="services__title">Геодезия</h3>
            <ul class="services__list">
              <li class="services__item">Топографическая съемка</li>
              <li class="services__item">Сопровождение строительно-монтажных работ</li>
              <li class="services__item">Наблюдение за деформациями зданий</li>
              <li class="services__item">Вынос в натуру проектов линейных сооружений</li>
              <li class="services__item">Определение объемов выемок и насыпей</li>
              <li class="services__item">Аэро-фото и видео съемка стоящихся объектов</li>
              <li class="services__item">Построение ЦММ и ЦМР высокого качества</li>
              <li class="services__item">Составление ортофотоплана местности</li>
            </ul>
          </div>

          <div class="services__block">
            <div class="services__image">
              <img src="../assets/img/services-2.png" alt="">
            </div>
            
            <h3 class="services__title">Проектирование</h3>
            <ul class="services__list">
              <li class="services__item">Жилые здания и сооружения</li>
              <li class="services__item">Дороги и инфраструктура</li>
              <li class="services__item">Промышленные здания и сооружения</li>
              <li class="services__item">Концептуальное проектирование и создание макетов</li>

            </ul>
          </div>

          <div class="services__block">
            <div class="services__image">
              <img src="../assets/img/services-3.png" alt="">
            </div>
            
            <h3 class="services__title">Строительство</h3>
            <ul class="services__list">
              <li class="services__item">Строительство промышленных объектов</li>
              <li class="services__item">Горно-капитальные работы</li>
              <li class="services__item">Крепление горных выработок</li>
              <li class="services__item">Бетонирование дорожного полотна</li>
              <li class="services__item">Монтаж освещения</li>
              <li class="services__item">Монтаж трубопроводов сжатого воздуха и воды</li>
              <li class="services__item">Монтаж ленточных конвейеров</li>
              <li class="services__item">Монтаж монорельсовой подвесной дороги</li>
              <li class="services__item">Усиление крепления кровки горных выработок канатными анкерами</li>

            </ul>
          </div>
          
        </div>
      </div>
    </section>

    <section class="home__path path">
      <div class="wrapper">
        <h2 class="section-title">Этапы строительства</h2>
        <div class="path__blocks">
          <div class="path__block">
            <div class="path__item path__item--1">
              <strong class="path__dot-text">Идея</strong>
              <span class="path__dot"></span>
              
              <p class="path__text">
                Завод? Фабрика? Цех? Ангар? Склад? Логистический комплекс? 
                Автомастерская? Автосалон? Спортивный комплекс? 
                Да, любое здание промышленного назначения, какое только хотите!
              </p>
            </div>

            <div class="path__item path__item--2">
              <strong class="path__dot-text">Техническое задание</strong>
              <span class="path__dot path__dot--small"></span>
              <p class="path__text">
                По эскизным чертежам составляется концептуальный макет для более полного представления о будущем объекте.
                Убедившись в правильном восприятии нашими специалистами Вашей идеи, составляется Техническое задание.
              </p>
            </div>

            <div class="path__item path__item--3">
              <strong class="path__dot-text">Проектирование</strong>
              <span class="path__dot path__dot--small"></span>
              <p class="path__text">
                В области проектирования промышленных зданий и сооружений 
                компания ООО «ПромТехСтрой» имеет огромный опыт. 
                Наши специалисты спроектируют так, чтобы проект 
                Вашего будущего здания был безопасным, надежным и, 
                в то же время, эстетичным и красивым!
              </p>
            </div>

            <div class="path__item path__item--4">
              <strong class="path__dot-text">Разрешение на строительство</strong>
              <span class="path__dot path__dot--small"></span>
              <p class="path__text">
                Перед строительством здания, необходимо получить разрешение у 
                органов власти. Мы поможем собрать все необходимые документы и 
                отнести их вместе с Вашим проектом будущего здания в администрацию!
              </p>
            </div>

            <div class="path__item path__item--5">
              <strong class="path__dot-text">Cтроительство</strong>
              <span class="path__dot path__dot--small"></span>
              <p class="path__text">
                Компания ООО «ПромТехСтрой»,  благодаря своему богатому опыту, 
                выполнит свою работу быстро и качественно!
              </p>
            </div>

            <div class="path__item path__item--6">
              <strong class="path__dot-text">Контроль</strong>
              <span class="path__dot path__dot--small"></span>
              <p class="path__text">
                На всем этапе строительства ведется постоянный контроль 
                соответствия проекту, соблюдения всех норм и правил, проверка качества
                 выполненных работ. Осуществляется ежемесячный контроль выполнения 
                 технологий строительства, подтверждение объемов строительства, 
                 сбор необходимых документов!
              </p>
            </div>

            <div class="path__item path__item--7">
              <strong class="path__dot-text">Сдача объекта</strong>
              <span class="path__dot"></span>
              <p class="path__text">
                Ваш объект регистрируется в едином государственном реестре объектов 
                недвижимости и выдается «свидетельство о рождении»! 
                Объект готов к эксплуатации!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="home__workers">
      <div class="wrapper">
        <div class="workers-block__description">
          <h3>
            Все наши специалисты успешно аттестованы аттестационной комиссией Ростехнадзора.
          </h3>
          <p>
            Это подтверждает высокое качество наших услуг и 
            гарантирует вам качественное и своевременное выполнение работы.
          </p>
          <a href="/docs/ISO.pdf" target="_blanc">Посмотреть</a>
        </div>
      </div>
    </section>

    <section class="home__partners partners">
      <div class="wrapper">
        <h2 class="section-title">Наши партнеры</h2>
        <ul class="partners__list">
          <!-- <li class="partners__item"><img src="../assets/img/Ekostroj-logo.png" alt="Экострой"></li>
          <li class="partners__item"><img src="../assets/img/Promtehstroj-logo.png" alt="Промтехстрой"></li> -->
          <li class="partners__item"><img src="../assets/img/PES.png" alt="Полимерэнергосибирь"></li>
          <li class="partners__item"><img src="../assets/img/SAGET.png" alt="Сагет"></li>
        </ul>
      </div>      
    </section>

    <section class="home__map map">
      <div class="wrapper">
        <h2 class="section-title">Филиалы</h2>
        <div class="map__container">
          <Map 
            class="map__map" 
            @show-branch="selectedBranch = $event"
          />
        </div>        
      </div>      
    </section>

    <Popup :isOpened="popupIsOpened" @close="popupIsOpened = false">
      <template v-slot:content>
        <component 
          :is="popupContent" 
          :data="popupData"

          @close-popup="popupIsOpened = false" 
        ></component>
      </template>
    </Popup>
  </div>
</template>

<script>
import { popup_mix } from '@/components/mixins/popup_mix'

import Map from '@/components/MapComponent.vue'
import FormComponent from '@/components/FormComponent'

export default {
  name: 'HomeView',
  components: { Map, FormComponent },
  mixins: [ popup_mix ],
  data() {
    return {
      selectedBranch: null
    }
  },
  methods: {

  }
}
</script>
