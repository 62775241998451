<template>
<div>
  <yandex-map 
    :settings="settings" 
    :coords="startCoords" 
    :controls="[]" 
    :zoom="4" 
    :options="{maxZoom:16, minZoom:4}"
    :show-all-markers="true"
  >
    <ymap-marker 
      v-for="branch in branches" :key="branch.id"
      :coords="branch.coords" 
      marker-id="branch"
      :balloon-template="balloonTemplate(branch)"
    />
  </yandex-map>

  <Popup :isOpened="popupIsOpened" @close="popupIsOpened = false">
    <template v-slot:content>
      <component 
        :is="popupContent" 
        :data="popupData"

        @close-popup="popupIsOpened = false"     
      ></component>
    </template>
  </Popup>
</div>
</template>

<script>
import { popup_mix } from '@/components/mixins/popup_mix'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import { HTTP } from '@/axois.config'
export default {
  mixins: [ popup_mix ],
  data() {
    return {
      settings: {
        apiKey: '0f35a098-67cb-49e4-a28d-27b3cd6167d0',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
        controls: ''
      },
      startCoords: [
        55.355669,
        86.088170,
      ],
      branches: [],
      selectedBranch: {}
    }
  },
  components: { yandexMap, ymapMarker },
  computed: {
    
  },
  methods: {
    balloonTemplate(branch) {
      return `
        <div class="map__branch">
          <h3>
            ${ branch.title }
          </h3>
          <p>${ branch.adress }</p>
          <a 
            class="map__phone" 
            href="tel:${ branch.phone }"
          >
            ${ branch.phone }
          </a>
        </div>
      `
    },
    getBranches() {
      HTTP.get('branches/').then(res => {
        this.branches = res.data
      })
    }
  },
  created() {
    this.getBranches()
  } 
}
</script>

<style scoped>
.ymap-container {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
</style>
