<template>
  <div class="custom-input">
    <label :for="id" v-show="label"> {{ label }}: </label>    
    <input 
      class="custom-input__input"
      :id="id"
      :type="type"
      :value="value"
      @input="onInput"
      :class="{
        'required valid': required && isValid, 
        'required invalid': required && !isValid
      }"
      autocomplete="off"
      @change="onChange"
      @keyup.enter="type === 'search' ? $emit('search-action') : ''"
      :readonly="readonly"
      :max="max"
      :min="min"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
export default {
  props: [
    'value', 
    'type', 
    'label', 
    'id', 
    'required', 
    'confirm', 
    'readonly', 
    'max', 
    'min',
    'placeholder'
  ],
  computed: {
    isValid() {
      if (!this.confirm) {
        return this.value ? true : false  
      } else {
        return this.value === this.confirm ? true : false
      }
    }
  },
  methods: {
    onInput(event) {
      if (this.type === 'number') {
        // event.target.value = event.target.value.replace(/[^\d]/g, '')
        if (this.max && event.target.value > this.max) {
          event.target.value = this.max
        }
        if (this.min && event.target.value < this.min) {
          event.target.value = this.min
        }
      }
      this.$emit('input', event.target.value)
    },
    onChange(event) {
      if (this.type === 'search') {
        this.$emit('search-action')
      }
      this.$emit('change', event.target.value)
    }
  }
}
</script>

