import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
import { HTTP } from '@/axois.config'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    meta: {layout: 'empty'},
    component: () => import('../views/PageNotFound')
  },
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'main'},
    component: HomeView
  },
  {
    path: '/geodesy',
    name: 'Geodesy',
    meta: {layout: 'main'},
    component: () => import('../views/GeodesyView')
  },
  {
    path: '/engineering',
    name: 'Engineering',
    meta: {layout: 'main'},
    component: () => import('../views/EngineeringView')
  },
  {
    path: '/building',
    name: 'Building',
    meta: {layout: 'main'},
    component: () => import('../views/BuildingView')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {layout: 'main'},
    component: () => import('../views/ContactsView')
  },
  {
    path: '/workers',
    name: 'Workers',
    meta: {layout: 'main'},
    component: () => import('../views/WorkersView')
  },
  {
    path: '/vacancy',
    name: 'Vacancy',
    meta: {layout: 'main'},
    component: () => import('../views/VacancyView')
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {layout: 'main'},
    component: () => import('../views/DocumentsView')
  },
  {
    path: '/articles',
    name: 'Articles',
    meta: {layout: 'main'},
    component: () => import('../views/ArticlesView')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'empty'},
    component: () => import('../views/LoginView')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/MainView')
  },
  {
    path: '/admin/branches',
    name: 'Branches',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/BranchesView')
  },
  {
    path: '/admin/news',
    name: 'AdminNews',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/NewsView')
  },
  {
    path: '/admin/vacancy',
    name: 'AdminVacancy',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/VacancyView')
  },
  {
    path: '/admin/workers',
    name: 'AdminWorkers',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/WorkersView')
  },
  {
    path: '/admin/gallery',
    name: 'AdminGallery',
    meta: {layout: 'admin', auth: true},
    component: () => import('../views/admin/GalleryView')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.auth)
  
  if (requireAuth) {
    const resData = await HTTP.get("auth/login").then(res => res.data)
    
    if (!resData.isAuth) router.push('/login')
    else {
      store.commit('saveUser', resData.user)
      next()
    }
  } else {
    next()
  }
})

export default router
