import Popup from '@/components/PopupComponent'

export const popup_mix = {
  components: { Popup },
  data() {
    return {
      popupIsOpened: false,
      popupContent: null,
      popupData: null,
    }
  },
  methods: {
    openPopup(component, data) {
      this.popupContent = component
      this.popupIsOpened = true
      this.popupData = data
    }
  },
  watch: {
    popupIsOpened() {
      if (this.popupIsOpened) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }
}