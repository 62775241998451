<template>
  <div class="form" v-if="!isDone">
    <div class="form__header">
      <h2>Отправить сообщение</h2>
    </div>

    <div class="form__body">
      <Input
        class="form__field"
        type="text"
        label="Ваше имя"
        id="name"
        :required="true"
        v-model="formData.name"
      />

      <p class="form__field">
        <span>Номер телефона:</span>
        <phone-mask-input
          :class="{
            'required valid': formData.phone.length === 12, 
            'required invalid': formData.phone.length !== 12
          }"
          v-model="formData.phone"
          autoDetectCountry
          showFlag
          inputClass="phone-input"
          wrapperClass="phone-wrapper"
          defaultCountry="ru"
          flagClass="phone-flag"
        />
      </p>      

      <p class="form__field">
        <span>Текст обращения:</span>
        <textarea 
          v-model="formData.text" 
          rows="5"
        ></textarea>
      </p> 

      <p class="form__checkbox">
        <input type="checkbox" v-model="agreement" id="agreement">
        <label for="agreement">Согласен(-а) с <a href="/docs/agreement.pdf" target="_blanc">условиями</a> обработки персональных данных</label>
      </p> 

    </div>

    <div class="form__footer">
      <button :disabled="!validForm" @click="onSubmit('form/')" class="button button--confirm">
        Отправить
      </button>
    </div>
    <HttpLoader v-if="loading" />
  </div>
  <div v-else class="form">
    <FormSuccess :text="message" :isSuccess="isSuccess" /> 
  </div>
     
</template>

<script>
import Input from "@/components/common/CustomInput";
import PhoneMaskInput from  "vue-phone-mask-input";
import { form_submit } from './mixins/form_submit'

export default {
  components: { Input, PhoneMaskInput },
  mixins: [ form_submit ],
  inject: ['http'],
  data() {
    return {
      agreement: false,
      formData: {
        name: '',
        phone: '',
        text: ''
      },
      requiredKeys: ['name', 'phone']
    }
  },
  computed: {
    validForm() {
      let condition = this.isValid && this.agreement && this.formData.phone.length === 12
      return condition ? true : false
    }
  }
}
</script>