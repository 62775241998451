<template>
  <transition name="fade">
    <div class="popup_background" v-if="isOpened">
      <div class="popup_content">
        <button @click="$emit('close')" class="close-button"></button>
        <div class="container">
          <slot name="content"></slot>
        </div>      
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['isOpened'],
  watch: {
    isOpened() {
      if (this.isOpened) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }
}
</script>