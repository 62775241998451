<template>
  <div class="admin-layout">
    <button 
      type="button" 
      class="admin-layout__toggle"
      @click="showMenu = !showMenu"
    >
    </button>
    <div class="admin-layout__left" :class="{showed: showMenu}">
      <a href="#">
        <img src="../assets/img/logo-2.png" alt="Логотип"> 
      </a> 
      <h4>Меню</h4>
      
      <ul class="admin-layout__nav" @click="showMenu = false">
        <li class="admin-layout__nav-item" v-if="isAdmin">
          <router-link to="/admin/branches">Филиалы</router-link>
        </li>

        <li class="admin-layout__nav-item" v-if="isAdmin">
          <router-link to="/admin/news">Новости</router-link>
        </li>

        <li class="admin-layout__nav-item" v-if="isAdmin">
          <router-link to="/admin/gallery">Галерея</router-link>
        </li>

        <li class="admin-layout__nav-item">
          <router-link to="/admin/workers">Сотрудники</router-link>
        </li>

        <li class="admin-layout__nav-item">
          <router-link to="/admin/vacancy">Вакансии</router-link>
        </li>
      </ul>

      <button type="button" class="button button--confirm" @click="logout">выйти</button>
    </div>

    <div class="admin-layout__right">
      <router-view />
    </div>

    <HttpLoader v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { loader_mix } from '@/components/mixins/loader_mix'
import { access_mix } from '@/components/mixins/access_mix'
export default {
  inject: ["http"],
  mixins: [ loader_mix, access_mix ],  
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    ...mapActions({
      getBranches: 'getBranches'
    }),
    logout() {
      this.loading = true
      this.http.get('auth/logout')
        .then(() => {
          if (this.$route.path !== '/login') {
            this.$router.push('/login')
          }          
        })
        .catch(e => console.log(e))
        .finally(() => this.loading = false)
    }
  },
  created() {
    this.getBranches()
  }
};
</script>