<template>
  <div class="success fade-success-icon">
    <img v-if="isSuccess" src="/img/success.png" class="success__icon">
    <img v-else src="/img/warning.png" class="success__icon">
    <p class="success__text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['text', 'isSuccess']
}
</script>

<style lang="scss" scoped>
.success {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success__icon {
  width: 75px;
  height: 75px;
}

.success__text {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}
</style>